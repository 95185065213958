import React, { useState,useEffect } from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Maps from './GoogleMaps';
import Panel from './Panel';
import InfoStation from './components/InfoStation';
import logo from './logo.svg';
import './App.css';

import { format } from 'date-fns'

import config from './config.json'
var Podio = require('podio-js').api;


// Example config file where you might store your credentials

// get the API id/secret
const clientId = config.clientId;
const clientSecret = config.clientSecret;

// get the app ID and Token for appAuthentication
const appId = config.appID;
const appToken = config.appToken;

// instantiate the SDK
const podio = new Podio({
  authType: 'app',
  clientId: clientId,
  clientSecret: clientSecret
});



// console.log(clientId);


function App() {


	const [isLoading, setLoading] = useState(true);
	const [stationGen, setStation] = useState([]);
	// const [messageCo, setMessageCo] = useState([]);
	const [width, setWidth] = useState("45%");
	

	const [state, setState] = useState({
		isPaneOpen: false,
		isPaneOpenLeft: false,
		dataPrecise: [],
	  });

	const handleMarkerClick = (ind) =>{
        // console.log(stationGen[ind]);
        // console.log(ind);
		getInfo(ind)
    }


	function handleWindowSizeChange() {
		// console.log(window.innerWidth);
		if(window.innerWidth < 768){
			setWidth('100%');
		}
		else{
			setWidth('45%');
		}
	}
	useEffect(() => {
		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	// const isMobile = width <= 768;

	const getInfo = (ind) => {
		// console.log(stationGen)
		// console.log(ind)
		let dataPrecise = []
		podio.authenticateWithApp(appId, appToken, (err) => {

			if (err) throw new Error(err);
  
			podio.isAuthenticated().then(() => {
				// Ready to make API calls in here...
				// console.log("authenticated");
		
				podio.request('GET', '/app/'+appId+'/item/'+ind).then(function(responseData) {
					// console.log(responseData.fields);
					// dataPrecise.msgSpe = "Aucun message"
					dataPrecise.piste = "Non connu"
					dataPrecise.date = "Non connue"
					dataPrecise.ngvInfo = "Non connu"
					// dataPrecise.bioGnv = "Non disponible"
					dataPrecise.distributeur = "Non connu"
					responseData.fields.forEach(field => {
						if(field.external_id == "nom-commercial-station"){
							dataPrecise.name = field.values[0].value.replace("<p>","").replace("</p>","")
						}
						else if(field.external_id == "adresse-station"){
							dataPrecise.adresse = field.values[0].formatted
						}
						else if(field.external_id == "etat-de-la-station"){
							dataPrecise.fonctionnement = field.values[0].value.text
						}
						else if(field.external_id == "date-de-mise-en-service"){

							let date = new Date(field.values[0].start_date);
							let formatDate = "Non connue"
							if(field.values[0].start_date != ""){
								formatDate = format(new Date(field.values[0].start_date), 'dd/MM/yyyy')
							}
							dataPrecise.date = formatDate
						}
						else if(field.external_id == "lien-photo"){
							dataPrecise.photo = field.values[0].embed.original_url
						}
						else if(field.external_id == "type-station"){
							dataPrecise.type = field.values[0].value.text
						}
						else if(field.external_id == "nombre-de-pistes"){
							dataPrecise.piste = Math.round(field.values[0].value)
						}
						else if(field.external_id == "nombre-de-distributeurs"){
							dataPrecise.distributeur = Math.round(field.values[0].value)
						}
						else if(field.external_id == "prix-fossile-eur-ttckg"){
							dataPrecise.prixFossile = field.values[0].value
						}
						else if(field.external_id == "prix-mix-eur-ttckg"){
							dataPrecise.prixMix = field.values[0].value
						}
						else if(field.external_id == "prix-biognc-eur-ttckg"){
							dataPrecise.prixBio = field.values[0].value
						}
						else if(field.external_id == "message-etat-station"){
							dataPrecise.msgStation = field.values[0].value
						}
						else if(field.external_id == "specificte-de-la-station"){
							dataPrecise.msgSpe = <li>{field.values[0].value}</li>
						}
						else if(field.external_id == "message-commercial"){
							dataPrecise.msgComm = field.values[0].value
							// setMessageCo(field.values[0].value)
						}
						else if(field.external_id == "ngv1ngv2"){
							dataPrecise.ngvInfo = field.values[0].value.text
						}
						else if(field.external_id == "bio-gnv"){
							// dataPrecise.bioGnv = "<li>Station 100% Bio GNV (pour les abonnés)</li>"
							dataPrecise.bioGnv = <li>Station 100% Bio GNV (pour les abonnés)</li>
						}
					});
					
					// console.log(dataPrecise);
					setState({dataPrecise:dataPrecise,isPaneOpenLeft:true})
				})
			})
		})

	}

	useEffect(() => {
		
		podio.authenticateWithApp(appId, appToken, (err) => {

			if (err) throw new Error(err);
  
			podio.isAuthenticated().then(() => {
				// Ready to make API calls in here...
				// console.log("authenticated");
		
				podio.request('POST', '/item/app/'+appId+'/filter').then(function(responseData) {
					// do something with the data  
					// console.log("data")
					// console.log(responseData)
					// console.log(responseData.items)

					let stations = []
					responseData.items.forEach(elem => {
						// console.log(elem)
						// const ourElem = elem.fields
						let station = []
						elem.fields.forEach(field => {
							

							station["id"] = elem.app_item_id;
							station["handle"] = () => handleMarkerClick(elem.app_item_id);
							if(field.field_id == "242076947"){
								// NOM
								station["nom"] = field.values[0].value
							}
							else if(field.field_id == "242076945"){
								// Localisation
								// console.log(field.values[0])
								// const coord = [34,34]
								station["coord"] = {lat:field.values[0].lat,lng:field.values[0].lng}
								station["city"] = field.values[0].formatted
								// ,field.values[0].values[0].long}
							}

							if(field.field_id == "242068851"){
								// console.log(field.values[0].value);
								// 5 = Décommisionnée
								if(field.values[0].value.id == 5){
									// console.log("delete");
									station = []
								}
							}
						});
						stations[elem.app_item_id] = station;

					});
			
					// console.log(stations);
					setStation(stations);
					setLoading(false);

				}).catch(err => console.log(err));
			
			}).catch(err => console.log(err));
		});

	},[]);

	

	return (
		<div className="App">
			{/* <div style={{ marginTop: "32px" }}>
				<button onClick={() => setState({ isPaneOpenLeft: true })}>
				Click me to open left pane with 20% width!
				</button>
			</div> */}
			<SlidingPane 
				// closeIcon={<div>Some div containing custom close icon.</div>}
				isOpen={state.isPaneOpenLeft}
				title="Informations sur la station"
				// title={messageCo}
				class="slidePanel"
				from="right"
				// width="45%"
				width={width}
				onRequestClose={() => setState({ isPaneOpenLeft: false })}
			>
				<InfoStation info={state.dataPrecise} />
			</SlidingPane>

			{isLoading ? (
				<>Chargement en cours</>
				):(
				<>
					<Maps station={stationGen} />
				</>
			)}
			
	  	</div>
		
	);
}

export default App;
