import React from 'react'
import { GoogleMap, useJsApiLoader,Marker,MarkerClusterer } from '@react-google-maps/api';
import configMap from './config/maps.json'
import customIcon from './img/icone.png'

const containerStyle = {
  width: '100%',
  height: '100vh'
};

// Centre de la France
const center = {
  lat: 46.227638,
  lng: 2.213749
};

function Maps(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyA4utD1AHNbE6yvR1GDqPG-xxzjh5Zw_aA"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onLoadInfo = infoWindow => {
    console.log('infoWindow: ', infoWindow)
  }
  const position = { lat: 33.772, lng: -117.214 }

    const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15
  }

   const clickCluster = (clust) => {
        let map = clust.getMap()
        map.setCenter(clust.getCenter()); // zoom to the cluster center
        map.setZoom(map.zoom + 3);
    }

    const optCluster = {
        // imagePath:'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
        imagePath:'/img/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
        zoomOnClick:false
    }
   
  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={6}
        options={{
          styles: configMap,
          backgroundColor: "white"
        }}  
        // onZoomChanged={ZoomChanged}
        // onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer 
          options={optCluster} 
          onClick={clickCluster}
          // zoom={6} 
          // zoomOnClick={true}
        >
          {(clusterer) =>
            props.station.map((item,index) => (
              <Marker 
                key={item.id}
                position={item.coord} 
                // color='blue'
                icon={customIcon}
                // icon={svgMarker}
                
                onClick={() => item.handle(index)}
                clusterer={clusterer}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
  ) : <></>
}

export default Maps;