import React from 'react'
import { Col, Row } from 'react-bootstrap';

import icoCamion from "../img/Tools_Car.png"

function InfoStation(props) {

    return (
        props.info ? (
        <>
            <Row id="blocPhoto">
                <div
                    id="photo"
                    style={{
                        backgroundImage: `url(${(props.info.photo)})`
                    }}
                >

                </div>
            </Row>
            <div id='mainContent'>
                <Row>
                    <Col xs="7"><h2>{props.info.name}</h2></Col>
                    <Col xs="5" style={{textAlign:'right'}}><div className='btnEtat'>{props.info.type}</div></Col>
                </Row>  
                <div><span style={{fontWeight:"normal",color:'#808080'}}>{props.info.adresse}</span></div>
                <div className="etatFonct"><span>{props.info.fonctionnement}</span></div>
                
                

                <Row className='msgStation'>
                    <Row>
                        <Col>
                            {props.info.msgStation}
                        </Col>
                    </Row>
                </Row>

               

                <Row className='boxInfo'>
                    <div className='headerBox'>
                        <img id="iconeInfo" src={icoCamion} ></img><span>Informations sur la station</span>
                    </div>
                    <div className='contentBox'>
                        <ul>
                            <li>Nombre de piste : {props.info.piste}</li>
                            <li>Nombre de distributeurs : {props.info.distributeur}</li>
                            <li>Date de mise en service : {props.info.date}</li>
                            <li>{props.info.ngvInfo}</li>
                            {props.info.bioGnv}
                            {props.info.msgSpe}
                            {/* <li>Message particulier : {props.info.msgSpe}</li> */}
                        </ul>
                    </div>
                    
                </Row>

                <Row className='stationInfo'>

                    <table>
                        <tbody>
                            <tr>
                                <th>Prix fossile</th>
                                <th>Prix mix</th>
                                <th>Prix BioGNC</th>
                            </tr>
                            <tr>
                                <td>{props.info.prixFossile} € TTC/kg</td>
                                <td>{props.info.prixMix} € TTC/kg</td>
                                <td>{props.info.prixBio} € TTC/kg</td>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row>
                    {props.info.msgComm}
                </Row>

            </div>
        </>):(<></>)
    )
}

export default InfoStation;